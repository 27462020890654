import { HttpClient } from '@angular/common/http'
import { SortDirection } from '@angular/material/sort'
import { IconDefinition } from '@fortawesome/free-solid-svg-icons'
import { Observable } from 'rxjs'
import { IResponse } from '../../../models/Common'

export interface IFilter {
  key: string
  label?: string
  filter?: any
  value?: any
  icon?: string | IconDefinition
}

type FindOptions = {
  page?: number
  size?: number
  sort?: string
  order?: SortDirection
  query?: string
  filters?: IFilter[]
}

const TABLE_PAGE_SIZE = 'table_page_size'

export type FindParams = {
  page?: number
  size?: number | undefined
  sort?: string | undefined
  order?: SortDirection | undefined
  query?: string | undefined
  filters?: IFilter[] | undefined
  ;[x: string]: unknown
}

export class DataService<T = unknown> {
  private _total = 0
  private _data: T[] = []
  private _detail?: T

  private _activeFilter: IFilter[] = []
  private _query?: string
  private _page = 1
  private _size = 40
  private _sort?: string
  private _order?: SortDirection

  constructor(private _http: HttpClient, private _router: string) {
    try {
      const locale_page_size = localStorage.getItem(TABLE_PAGE_SIZE)
      if (!locale_page_size) localStorage.setItem(TABLE_PAGE_SIZE, `${this._size}`)
      this.size = +(localStorage.getItem(TABLE_PAGE_SIZE) || this._size)
    } catch (ex) {
      console.warn(ex)
    }
  }

  public getDataWithOptions = ({ page, size, sort, order, query, filters }: FindOptions) =>
    this.getData(page, size, sort, order, query, filters)

  public getData = (
    page = this.page,
    size = this.size,
    sort = this._sort,
    order = this._order,
    query?: string,
    filters: IFilter[] = this.activeFilter,
  ): Observable<IResponse<T[]>> => {
    const uri = new URL(this._router, window.location.origin)
    const _query = query || this.query
    uri.searchParams.set('page', `${page}`)
    uri.searchParams.set('size', `${size}`)
    if (_query?.trim().length) uri.searchParams.set('query', _query)
    if (filters?.length) uri.searchParams.set('filter', JSON.stringify({ $and: filters?.map(f => f?.value) }))
    if (sort) uri.searchParams.set('sort', `${sort}`)
    if (order) uri.searchParams.set('order', `${order}`)
    return this.http.get<IResponse<T[]>>(uri.toString())
  }

  public findAll = ({ page = this.page, size = this.size, sort, order, query, filters }: FindParams) =>
    this.getData(page, size, sort, order, query, filters)

  public getDataById(id: string): Observable<T> {
    return this.http.get<T>(`${this._router}/${id}`)
  }

  public create(body: T | FormData): Observable<T> {
    return this.http.post<T>(this._router, body)
  }

  public updateData = (id: string, body: Partial<T> | FormData) => this.http.put<T>(`${this._router}/${id}`, body)

  public deleteDataById = (id: string): Observable<T> => this.http.delete<T>(`${this._router}/${id}`)

  private setLocalePageSize = (size: number) => {
    localStorage.setItem(TABLE_PAGE_SIZE, `${size || this._size}`)
    return size
  }

  getFilter(key: string) {
    return this._activeFilter?.find(f => f.key === key)
  }

  public get http(): HttpClient {
    return this._http
  }

  public get router() {
    return this._router
  }

  public get total() {
    return this._total
  }
  public set total(value) {
    this._total = value
  }
  public get data(): T[] {
    return this._data
  }
  public set data(value: T[]) {
    this._data = value
  }
  public get detail(): T | undefined {
    return this._detail
  }
  public set detail(value: T | undefined) {
    this._detail = value
  }
  public get activeFilter(): IFilter[] {
    return this._activeFilter
  }
  public set activeFilter(value: IFilter[]) {
    this._activeFilter = value
  }
  public get query(): string | undefined {
    return this._query
  }
  public set query(value: string | undefined) {
    this._query = value
  }
  public get size(): number | undefined {
    return this._size
  }
  public set size(value: number | undefined) {
    if (value && this.size !== value) this.setLocalePageSize(value)
    this._size = value || this._size
  }
  public get page() {
    return this._page
  }
  public set page(value) {
    this._page = value
  }
  public get sort() {
    return this._sort
  }
  public set sort(value) {
    this._sort = value
  }
  public get order() {
    return this._order
  }
  public set order(value) {
    this._order = value
  }
}
