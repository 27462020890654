import { HttpClient, HttpResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { Observable } from 'rxjs/internal/Observable'
import { login, logout, saveToken } from '../../../core/actions/auth.actions'
import { AppState } from '../../../core/reducers'
import { selectBaseUrl } from '../../../core/reducers/auth.reducer'
import { AuthUser } from '@sauna-net/dto'

export type AuthResponse = {
  token: string
  user: AuthUser
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _loading = true
  authBaseUrl: string | undefined

  constructor(private router: Router, private http: HttpClient, private store: Store<AppState>) {
    this.checkAuth()
    this.store.select(selectBaseUrl).subscribe(val => (this.authBaseUrl = val))
  }

  makeAuth = (body: { username: string; password: string }): Observable<HttpResponse<AuthResponse>> =>
    this.http.post<AuthResponse>('/api/auth', body, {
      observe: 'response',
    })

  logIn = ({ token, user }: AuthResponse) => {
    this.loading = false
    if (!user.user_name) {
      localStorage.removeItem('JWT_TOKEN')
      this.store.dispatch(logout())
      this.router.navigate(['login'])
      return
    }
    this.store.dispatch(login({ token, user }))
    if (this.router.url === '/login') this.router.navigate([this.authBaseUrl || '/'])
  }

  logOut() {
    localStorage.removeItem('JWT_TOKEN')
    this.store.dispatch(logout())
    this.router.navigate(['login'])
  }

  private checkAuth() {
    const localToken = window?.localStorage?.getItem('JWT_TOKEN')
    if (localToken) {
      this.store.dispatch(saveToken({ token: localToken }))
      this.authenticate().subscribe({
        next: this.logIn,
        error: () => {
          this.router.navigate(['login'])
        },
      })
    } else this.loading = false
  }

  private authenticate = (): Observable<AuthResponse> => this.http.get<AuthResponse>('/api/auth')

  public get loading() {
    return this._loading
  }
  public set loading(value) {
    this._loading = value
  }
}
