import { ActionReducer, createReducer, createSelector, on } from '@ngrx/store';
import { authenticate, login, logout, saveToken, saveUser, storeBaseUrl } from '../actions/auth.actions';
import { AppState } from '../reducers';
import { IAuthState } from './index';
const initialState = {
  isLoggedIn: false,
};

const _authReducer = createReducer<IAuthState>(
  initialState,
  on(login, (state, { token, user }) => {
    localStorage.setItem('JWT_TOKEN', token);
    return { ...state, isLoggedIn: true, token, user };
  }),
  on(saveToken, (state, { token }) => ({ ...state, token })),
  on(authenticate, (state) => state),
  on(saveUser, (state, { user }) => ({ ...state, user })),
  on(logout, (state) => ({ ...state, isLoggedIn: false, token: undefined, user: undefined })),
  on(storeBaseUrl, (state, { url }) => ({ ...state, baseUrl: url })),
);

export const selectAuth = (state: AppState) => state.auth;

export const selectBaseUrl = createSelector(selectAuth, (state) => state.baseUrl);

export const auth: ActionReducer<IAuthState> = (state, action) => _authReducer(state, action);

export const selectAuthUser = createSelector(selectAuth, (state) => state.user);
